import {CanActivateFn, Router} from "@angular/router";
import {inject} from "@angular/core";
import {extendAndNavigate} from "../routing-util";

export const replaceOutletToPrimary: CanActivateFn = (route, state) => {
	const router = inject(Router);
	const parsedUrl = router.parseUrl(state.url);
	const path = parsedUrl.root.children[route.outlet]?.segments.map(s => s.path).join("/");

	return extendAndNavigate(state.url, {skipLocationChange: true}, () => {
		if (window.location.pathname !== `/${path}`) {
			history.pushState(null, "", path);
		}
	});
};
