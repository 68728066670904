import {BrowserModule, Title} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {APP_INITIALIZER, isDevMode, NgModule} from "@angular/core";
import {HttpClient, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";

import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
// 3rd party
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
// main modules
import {CommonsModule} from "./commons/commons.module";
import {SocialModule} from "./social/social.module";
import {HomeModule} from "./home/home.module";
import {UserSessionService} from "./auth/session/services/user-session/user-session.service";
import {ApiCommunicationService} from "./shared/model/services/api-communication/api-communication.service";
import {ModelModule} from "./shared/model/model.module";
import {PageTitleService} from "./commons/services/page-title/page-title.service";
import {FeedEventsService} from "./commons/services/feed-events/feed-events.service";
import {NgxOAuthModule} from "@bkoti/ngx-oauth-client";
import {OAuthApiClient} from "./shared/model/utility/OAuthApiClient";
import {ModalService} from "./commons/services/modal/modal.service";
import {MODAL_SERVICE_TOKEN, ModalServiceProxy} from "./commons/services/modal/modal.service.proxy";
import {CartService} from "./shared/model/services/cart/cart.service";
import {SweetAlertService} from "./commons/services/sweetalert/sweet-alert.service";
import {NotificationDataService} from "./shared/model/services/notification-data/notification-data.service";
import {userSessionStarterFactory} from "./auth/session/util/UserSessionStarterFactory";
import {PermissionLogicService} from "./shared/model/services/permission-logic/permission-logic.service";
import {NoAuthGuardService} from "./auth/auth-guards/guard/noauth-guard.service";
import {AuthGuardService} from "./auth/auth-guards/guard/auth-guard.service";
import {HomeFeedService} from "./home/services/home-feed.service";
import {UpdateService} from "./commons/services/update/update.service";
import {ReportService} from "./social/services/report/report.service";
import {MasonryService} from "./commons/services/masonary/masonry.service";
import {NgProgressModule} from "ngx-progressbar";
import {CommonModule} from "@angular/common";
import {ServiceWorkerModule} from "@angular/service-worker";
import {provideAngularQuery, QueryClient} from "@tanstack/angular-query-experimental";
import {AngularQueryDevtools} from "@tanstack/angular-query-devtools-experimental";
import {MobileNavbarComponent} from "./commons/components/mobile-navbar.component";

// http loader for translations file
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, "/assets/i18n/", ".json");
}

@NgModule({
	declarations: [
		AppComponent,
	],
	bootstrap: [AppComponent], imports: [
		// angular modules
		BrowserModule,
		BrowserAnimationsModule,
		CommonModule,
		AppRoutingModule,
		AngularQueryDevtools,
		// 3rd party modules
		// ngx-translate
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		// ng-bootstrap
		NgbModule,
		// ngx-progressbar
		NgProgressModule,
		// ngx-facebook
		// FacebookModule.forRoot(),
		// ngx-oauth
		NgxOAuthModule,
		// lovegivr modules
		CommonsModule,
		SocialModule,
		HomeModule,
		ModelModule,
		ServiceWorkerModule.register("ngsw-worker.js", {
			enabled: !isDevMode(),
			// Register the ServiceWorker as soon as the application is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: "registerWhenStable:30000"
		}),
		MobileNavbarComponent
	], providers: [
		provideHttpClient(),
		provideAngularQuery(new QueryClient({ defaultOptions: { queries: { staleTime: 10000 } } })),
		// Title service
		Title,
		// Page title service
		PageTitleService,
		// User session handler service
		UserSessionService,
		// Communication service
		ApiCommunicationService,
		// OAuth API client
		OAuthApiClient,
		// Feed events
		HomeFeedService,
		FeedEventsService,
		// Modal service and proxy
		{
			provide: MODAL_SERVICE_TOKEN,
			useClass: ModalService
		},
		// Modal service proxy
		ModalServiceProxy,
		// hook UserSessionService's session start action into app initialization hook
		{
			provide: APP_INITIALIZER,
			useFactory: userSessionStarterFactory,
			deps: [UserSessionService],
			multi: true
		},
		// Alerts
		SweetAlertService,
		// Notifications
		NotificationDataService,
		// Cart service
		CartService,
		// Permissions logic
		PermissionLogicService,
		UpdateService,
		ReportService,
		MasonryService,
		// Auth guard (prevents unauthorized users from using 'login-required' pages)
		AuthGuardService,
		NoAuthGuardService,
		provideHttpClient(withInterceptorsFromDi())
	]
})
export class AppModule {
}
