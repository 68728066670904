<div id="page-wrapper" [ngClass]="{'scrolled': isScrolled}">

	<header>
		<!--progressbar-->
		<ng-progress [color]="'#DA3D6A'" [spinner]="false"></ng-progress>
		<!--navbar-->
		<app-navbar></app-navbar>
	</header>

	<main id="main-layout">
		<router-outlet></router-outlet>
	</main>

	<footer class="md:hidden">
		<app-mobile-navbar/>
	</footer>
</div>

<router-outlet name="secondary"></router-outlet>
<router-outlet name="dialog"></router-outlet>

<!--Cache registration img to prevent flickering modal-->
<img src="../assets/images/register-image.jpg" style="display:none" />

<angular-query-devtools initialIsOpen />
