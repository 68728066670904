import {ComponentRef, enableProdMode} from "@angular/core";
import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";

import {AppModule} from "./app/app.module";
import {environment} from "./environments/environment";
import {enableDebugTools} from "@angular/platform-browser";

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule).then((appRef) => {

	// enable debugging tools if not on production
	if (!environment.production) {
		enableDebugTools(<any>appRef);
	}
});
