import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {DialogViewComponent} from "./shared/routing/view/dialog-view.component";
import {LoginModalComponent} from "./shared/login/modals/login-modal/login-modal.component";
import {ensureSkipLocationChange} from "./shared/routing/guards/ensure-skip-location-change";
import {replaceOutletToPrimary} from "./shared/routing/guards/replace-outlet-to-primary";

const routes: Routes = [
	// lazy loaded home
	{
		path: "",
		loadChildren: () => import("./home/home.module").then(m => m.HomeModule),
		pathMatch: 'full'
	},
	// lazy loaded blogging
	{
		path: "blogs",
		loadChildren: () => import("./blogs/blogs.module").then(m => m.BlogsModule)
	},
	// lazy loaded posts
	{
		path: "posts",
		loadChildren: () => import("./social-features/posts/posts.module").then(m => m.PostsModule)
	},
	{
		path: "posts",
		outlet: "secondary",
		component: DialogViewComponent,
		loadChildren: () => import("./social-features/posts/posts.module").then(m => m.PostsModule),

		canActivate: [replaceOutletToPrimary],
		runGuardsAndResolvers: "always"
	},
	{
		path: "",
		outlet: "dialog",
		component: DialogViewComponent,
		children: [{
			path: "login",
			component: LoginModalComponent,
			canActivate: [ensureSkipLocationChange]
		}]
	},
	// lazy loaded registration module
	{
		path: "registration",
		loadChildren: () => import("./registration/registration.module").then(m => m.RegistrationModule)
	},
	// lazy loaded charity module
	{
		path: "charities",
		loadChildren: () => import("./charity/charity.module").then(m => m.CharityModule)
	},
	// lazy loaded information module
	{
		path: "information",
		loadChildren: () => import("./information/information.module").then(m => m.InformationModule)
	},
	// lazy loaded profile module
	{
		path: "dashboard",
		loadChildren: () => import("./my-profile/my-profile.module").then(m => m.MyProfileModule)
	},
	// lazy loaded notifications
	{
		path: "notifications",
		loadChildren: () => import("./notification/notification.module").then(m => m.NotificationModule)
	},
	// lazy loaded checkout
	{
		path: "checkout",
		loadChildren: () => import("./checkout/checkout.module").then(m => m.CheckoutModule)
	},
	// lazy loaded user profile pages
	{
		path: "user",
		loadChildren: () => import("./profile/profile.module").then(m => m.ProfileModule)
	},

	// AUTH RELATED
	{
		path: "email-verification",
		loadChildren: () => import("./auth/email-verification/email-verification.module").then(m => m.EmailVerificationModule)
	},
	{
		path: "reset-password",
		loadChildren: () => import("./auth/forgot-password/forgot-password.module").then(m => m.ForgotPasswordModule)
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		useHash: false,
		anchorScrolling: "enabled",
		bindToComponentInputs: true,
		paramsInheritanceStrategy: "always"
	})],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
