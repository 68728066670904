import {Component, inject} from "@angular/core";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {CommonsModule} from "../commons.module";
import {UserSessionService} from "../../auth/session/services/user-session/user-session.service";
import {AsyncPipe, NgIf} from "@angular/common";

@Component({
  selector: 'app-mobile-navbar',
  standalone: true,
	imports: [
		RouterLink,
		CommonsModule,
		AsyncPipe,
		RouterLinkActive,
		NgIf
	],
  template: `
	  <nav id="mobile-nav">
		  <div id="mobile-nav-menu">
			  <a class="menu-item" [routerLink]="['/']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
				  <svg id="Group_4" data-name="Group 4" xmlns="http://www.w3.org/2000/svg" width="29.988" height="29.969"
					   viewBox="0 0 29.988 29.969">
					  <path id="Path_7" data-name="Path 7"
							d="M21.242,22.632v7.5h5a3.749,3.749,0,0,0,3.749-3.749v-11.4a2.5,2.5,0,0,0-.7-1.739L18.666,1.765A5,5,0,0,0,11.6,1.487q-.144.134-.278.278L.726,13.241A2.5,2.5,0,0,0,0,15V26.381a3.749,3.749,0,0,0,3.749,3.749h5v-7.5a6.248,6.248,0,1,1,12.5,0Z"
							transform="translate(0 -0.16)" fill="#000000"/>
					  <path id="Path_8" data-name="Path 8"
							d="M195.749,319.841A3.749,3.749,0,0,0,192,323.59v7.5h7.5v-7.5A3.749,3.749,0,0,0,195.749,319.841Z"
							transform="translate(-180.754 -301.117)" fill="#000000"/>
				  </svg>
				  <span>Home</span>

			  </a>

			  <!--share the love-->
			  <app-add-content *ngIf="(userSessionService.isLoggedIn | async) && !userSessionService.isMerchantPending"
							   placement="top"></app-add-content>

			  <!---->
			  <div class="menu-item" [routerLink]="['/checkout']" [routerLinkActive]="'active'">
				  <app-cart-menu [skipMenu]="true"></app-cart-menu>
				  <span>Cart</span>

			  </div>
			  <a class="menu-item" *ngIf="(userSessionService.isLoggedIn | async)" [routerLink]='["/dashboard"]'
				 routerLinkActive="active">
				  <!--profile icon-->
				  <svg xmlns="http://www.w3.org/2000/svg" width="52.108" height="69" viewBox="0 0 52.108 69">
					  <g id="user" transform="translate(-3 -0.353)">
						  <path id="Path_28" data-name="Path 28"
								d="M40.758,14H17.35A14.367,14.367,0,0,0,3,28.35v14.6H55.108V28.35A14.367,14.367,0,0,0,40.758,14Z"
								transform="translate(0 26.404)"/>
						  <ellipse id="Ellipse_17" data-name="Ellipse 17" cx="18" cy="17" rx="18" ry="17"
								   transform="translate(11.045 0.353)"/>
					  </g>
				  </svg>

				  <span>Profile</span>
			  </a>

			  <a class="menu-item" *ngIf="!(userSessionService.isLoggedIn | async)" [routerLink]="[{outlets: {dialog: 'login'}}]"
				 routerLinkActive="active">
				  <!--profile icon-->
				  <svg xmlns="http://www.w3.org/2000/svg" width="52.108" height="69" viewBox="0 0 52.108 69">
					  <g id="user" transform="translate(-3 -0.353)">
						  <path id="Path_28" data-name="Path 28"
								d="M40.758,14H17.35A14.367,14.367,0,0,0,3,28.35v14.6H55.108V28.35A14.367,14.367,0,0,0,40.758,14Z"
								transform="translate(0 26.404)"/>
						  <ellipse id="Ellipse_17" data-name="Ellipse 17" cx="18" cy="17" rx="18" ry="17"
								   transform="translate(11.045 0.353)"/>
					  </g>
				  </svg>

				  <span>Login</span>
			  </a>
		  </div>
	  </nav>
  `,
	styleUrl: './mobile-navbar.component.scss'
})
export class MobileNavbarComponent {
	readonly userSessionService = inject(UserSessionService);
}
