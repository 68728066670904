import {Injectable} from "@angular/core";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {
	ProfileImageUploadModalComponent
} from "../../../my-profile/modals/profile-image-upload-modal/profile-image-upload-modal.component";
import {Router} from "@angular/router";

@Injectable()
export class ModalService {

	constructor(
		private modalService: NgbModal,
		private router: Router
	) {
	}

	public showLoginModal(): void {
		this.router.navigate([{outlets: {dialog: "login"}}]);
	}

	public showImageUploadModal(): void {
		const modal = this.modalService.open(ProfileImageUploadModalComponent, {size: "lg", windowClass: "modal-xl"});
		(<ProfileImageUploadModalComponent>modal.componentInstance).modalRef = modal;
	}

	public closeLoginModal() {
		this.modalService.dismissAll();
	}

}
