import {NavigationExtras, Router, UrlTree} from "@angular/router";
import {inject} from "@angular/core";

export function extendAndNavigate(url: string, extras: NavigationExtras, onNavigation?: (success: boolean) => void) {
	const router = inject(Router);

	const currentExtras = router.getCurrentNavigation()?.extras;

	const isExtrasIdentical = Object.entries(extras).every(([key, value]) => {
		return currentExtras[key] === value;
	})

	if (isExtrasIdentical) {
		return true;
	}

	const urlTree = router.parseUrl(url);
	navigateWithExtras(urlTree, extras).then(success => {
		onNavigation?.(success);
	})

	return false;
}

async function navigateWithExtras(url: string | UrlTree, extras: NavigationExtras) {
	const router = inject(Router);
	const actualExtras = router.getCurrentNavigation()?.extras;

	return router.navigateByUrl(url, {...actualExtras, ...extras});
}
