import {Component} from "@angular/core";
import {ActivatedRoute, Router, RouterModule} from "@angular/router";

@Component({
	selector: "app-dialog-view",
	standalone: true,
	imports: [
		RouterModule
	],
	template: `
		<dialog class="nui-dialog p-0"
				(close)="removeNamedOutlet()"
				(mousedown)="$event.target === dialog ? dialog.close() : ''"
				#dialog
		>
			<router-outlet (activate)="dialog.showModal()" (deactivate)="dialog.close()"/>
		</dialog>
	`,
	styles: [`
	`]
})
export class DialogViewComponent {
	constructor(private route: ActivatedRoute, private router: Router) {
	}

	removeNamedOutlet() {
		this.router.navigate([{outlets: {[this.route.outlet]: null}}])
	}
}
